import { authStateAtom } from '../models/atoms/auth_atoms';
import { setRecoil } from "recoil-nexus";
import { tokenResponseInterface } from "../models/interfaces/auth_interfaces";

const auth_link = process.env.REACT_APP_AUTH_API_URL;

export const auth_controller = {
  
    get_auth_key: async function (username: string, password: string, callback = () => { }) {
      var obj = {
        link: auth_link + '/token',
        object: {
          method: 'POST',
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: 'username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password)
        }
      }
      await fetch(obj.link, obj.object).then(async response => {
        if (!response.ok) {
          const json = await response.json();
          alert(json.detail);
          setRecoil(authStateAtom, { token: '', validToken: false, showInvalidCredWarning: true });
          return;
        }
        const tokenResponse = (await response.json()) as tokenResponseInterface
        setRecoil(authStateAtom, {
              token: tokenResponse?.access_token ?? '', validToken: true, showInvalidCredWarning: false,
              });
        localStorage.setItem('token', tokenResponse?.access_token ?? '');
        callback();
      })
    },
  
    check_token: function () {
      const token = localStorage.getItem('token')
      const obj = {
        link: auth_link + '/validate_token',
        object: {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(response => {
          if (response.ok) {
            setRecoil(authStateAtom, { token: token ?? '', validToken: true, showInvalidCredWarning: false });
          }
        })
    },
  
    logout: function () {
      localStorage.setItem('token', '');
      setRecoil(authStateAtom, { token: '', validToken: false, showInvalidCredWarning: false })
    }
  };
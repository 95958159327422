/* eslint-disable no-useless-escape */
import React from 'react';
import { DeviceInfoPanel } from "../components/device/DeviceInfo";
import '../../App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import { mapStateAtom } from '../../models/atoms/ui_atoms';
import { panelSizesAtom } from "../../models/atoms/ui_atoms";
import { getRecoil, setRecoil } from "recoil-nexus";

import { useState, memo, useEffect } from "react";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane";
import { MapComponent } from "../components/MapComponent";
import { sizes } from '../styles/style_variables';
import { DeviceListComponent } from "../components/device/DeviceList";
import { device_controller } from '../../controllers/device_controller';
import { company_controller } from '../../controllers/company_controller';
import { selectedDeviceStateAtom } from '../../models/atoms/device_atoms';

export const DeviceMetricsPage = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  useEffect(() => {
    setRecoil(mapStateAtom, {
      ...getRecoil(mapStateAtom),
      required_update: true,
      requested_zoom: 5,
    })
  });

  useEffect(() => {
    device_controller.get_device_list();
    company_controller._get_labels();
    const selectedDevice = getRecoil(selectedDeviceStateAtom);
    if (selectedDevice) {
      device_controller.select_device(selectedDevice.device_id)
    }
  }, [])
  return <VerticalSplit style={{ height: "100%" }}></VerticalSplit>
};


const VerticalSplit = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const vh =
    Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) -
    sizes.header;
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const [leftWidth, setLeftWidth] = useState(Math.floor(vw * 0.6).toString() + "px");
  const [rightWidth, setRightWidth] = useState(Math.floor(vw * 0.4).toString() + "px");

  const onChange = (size: [string, string]) => {
    setLeftWidth(size[0]);
    setRightWidth((vw - parseInt(size[0].replace(/px/, ""))) + "px");
  };

  // Store the panel size in a Recoil Atom
  setRecoil(panelSizesAtom, { ...getRecoil(panelSizesAtom), vLeft: leftWidth, vRight: rightWidth });

  // Ignores required due to bugs in split pane typescript definitions
  return (
    <>
      {/* @ts-ignore */}
      <SplitPane
        split="vertical"
        // @ts-ignore
        onChange={(size: [string, string]) => {
          onChange(size);
        }}
      >
        {/* @ts-ignore */}
        <Pane initialSize={leftWidth} minSize="50%" maxSize="90%">
          <div>
            <HorizontalSplit style={{ maxHeight: vh, height: vh }}></HorizontalSplit>
          </div>
        </Pane>
        {/* @ts-ignore */}
        <Pane minSize="10%" maxSize="90%" style={{ overflowY: "scroll", height: '100%' }}>
          <div style={{ height: '100%', padding: '1rem 1rem 0 1rem'}}>
            <DeviceInfoPanel style={{ height: '100%' }}></DeviceInfoPanel>
          </div>
        </Pane>
      </SplitPane>
    </>
  );
});


const HorizontalSplit = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const vh =
    Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) -
    sizes.header;
  const [topHeight, setTopHeight] = useState(Math.floor(vh / 2).toString() + "px");
  const [bottomHeight, setBottomHeight] = useState(Math.floor(vh / 2).toString() + "px");

  const onChange = (size: [string, string]): void => {
    setTopHeight(size[0]);
    setBottomHeight(size[1]);
  };
  setRecoil(panelSizesAtom, { ...getRecoil(panelSizesAtom), hTop: topHeight, hBottom: bottomHeight });
  return (
    <div>
      {/* @ts-ignore */}
      <SplitPane split="horizontal" onChange={size => onChange(size)}>
        {/* @ts-ignore */}
        <Pane
          initialSize={topHeight}
          minSize={Math.floor(0.1 * vh).toString() + "px"}
          maxSize={Math.floor(0.9 * vh).toString() + "px"}
        >
          <div id="mapID">
            <MapComponent />
          </div>
        </Pane>
        {/* @ts-ignore */}
        <Pane
          // initialSize={bottomHeight}
          // minSize={Math.floor(0.1 * vh).toString() + "px"}
          // maxSize={Math.floor(0.9 * vh).toString() + "px"}
        >
          <div style={{ minHeight: bottomHeight, paddingTop: 5 }}>
            <div
              style={{
                maxHeight: bottomHeight,
                height: parseInt(bottomHeight) - sizes.simInfo,
                // overflowY: "scroll"
                overflowY: 'hidden',
              }}
            >
              <DeviceListComponent />
            </div>
          </div>
        </Pane>
      </SplitPane>
    </div>
  );
});







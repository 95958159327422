import '../../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../../styles/shared_styles.css';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { mapStateAtom, panelSizesAtom } from '../../../models/atoms/ui_atoms';
import { labelsAtom } from "../../../models/atoms/company_atoms";
import { selectedDeviceStateAtom, filterStateAtom, filteredDeviceListSelector, systemTypesSelector } from "../../../models/atoms/device_atoms";
import { getRecoil, setRecoil } from "recoil-nexus";
// @ts-ignore
import sort_icon from "../../../assets/sort-icon.svg"
import { deviceListComponentStyles} from '../../styles/device_metrics_page_styles';
import { useState, memo, useEffect } from "react";
import { device_controller } from "../../../controllers/device_controller";
import React from 'react';
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the useTranslation hook
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel, Typography } from '@mui/material';
import { primary_background_colour } from '../../styles/style_variables';
import { alphanumeric_comparator } from '../../../utils/helper_functions';

export const DeviceListComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { t } = useTranslation(); // Use the translation hook
  const company = useRecoilValue(labelsAtom);
  const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
  const [filter, setFilterState] = useRecoilState(filterStateAtom);
  const filteredDevices = useRecoilValue(filteredDeviceListSelector);
  const resetFilter = useResetRecoilState(filterStateAtom);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const setSelectedDevice = useSetRecoilState(selectedDeviceStateAtom);
  const [sortDevicesBy, setSortDevicesBy] = useState('device_id');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const alertLabels = ["Low battery", "No messages", "High ROC", "Blockage", "High Level"];
  const systemTypes = useRecoilValue(systemTypesSelector);

  if (!filteredDevices.devices.length) {
    setRecoil(mapStateAtom, {
      ...getRecoil(mapStateAtom),
      required_update: true,
      requested_zoom: 5,
    });
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    var aVal: any = a[orderBy];
    var bVal: any = b[orderBy];
    if (orderBy === 'device_id') {
      aVal = Number(aVal);
      bVal = Number(bVal);
    }
    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  }
  
  type Order = 'asc' | 'desc';
  
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: any, b: any) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function updateOrder(orderBy: string) {
    if (sortDevicesBy !== orderBy) {
      setSortDevicesBy(orderBy);
      setSortDirection('asc');
    } else {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
  }

  const headers = [
    { label: 'Device ID', key: 'device_id', sort: true },
    { label: 'Fill %', key: 'current_level_percentage', sort: true },
    { label: 'Labels/Alerts', key: 'labels', sort: false },
    { label: 'Pinned', key: 'pinned', sort: false },
  ]


  const panel = useRecoilValue(panelSizesAtom);
  const width = (parseInt(panel.vLeft.replace(/px/, "")) - 30) + "px";


  return <article data-testid="Device List Component" style={{
    backgroundColor: primary_background_colour,
    borderRadius: 5,
    margin: 15,
    padding: '0.5rem 1rem',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    width,
    overflow: 'hidden',
  }}>
    <div className={css(deviceListComponentStyles.search_filter_container)}>
      <input className={css(deviceListComponentStyles.search)} type="text" placeholder={t("Search Devices")}
          value={filter.searchText}
          onChange={(event) => setFilterState({ ...filter, searchText: event.target.value })}/>
      <div className={css(deviceListComponentStyles.filter_buttons_container)}>
        <button className={css(button_styles.main_button)} style={{width: '8rem', margin: '0 10px !important', padding: '0.4rem 0.7rem', marginRight: '0.6rem'}} onClick={() => setFilterDropdown(true)}>
        <i className="bi-filter"></i> {t("Filter")}</button>
        <div style={{position: 'absolute'}}>
      {filterDropdown && <div className='dropdown' style={{top: '4.2rem', left: '7.5rem', maxHeight: '11rem', overflow: 'auto'}} onMouseLeave={() => setFilterDropdown(false)} >
        <p className={filter.pinned ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => 
                {
                setSelectedDevice(null);
                setFilterState({ ...filter, pinned: !filter.pinned });
                await device_controller.get_device_list();
                setRecoil(mapStateAtom, {
                  ...getRecoil(mapStateAtom),
                  required_update: true,
                  requested_zoom: 5,
                });
              }
                }> {t("Pinned")} </p>
        <p className={filter.lowBattery ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
          setSelectedDevice(null);
          setFilterState({ ...filter, lowBattery: !filter.lowBattery });
          await device_controller.get_device_list();
          setRecoil(mapStateAtom, {
            ...getRecoil(mapStateAtom),
            required_update: true,
            requested_zoom: 5,
          });
        }}>{t("Low battery")}</p>
        <p className={filter.noMessages ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
          setSelectedDevice(null);
          setFilterState({ ...filter, noMessages: !filter.noMessages });
          await device_controller.get_device_list();
          setRecoil(mapStateAtom, {
            ...getRecoil(mapStateAtom),
            required_update: true,
            requested_zoom: 5,
          });
        }}>{t("No messages")}</p>
        <p className={filter.highROC ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
          setSelectedDevice(null);
          setFilterState({ ...filter, highROC: !filter.highROC });
          await device_controller.get_device_list();
          setRecoil(mapStateAtom, {
            ...getRecoil(mapStateAtom),
            required_update: true,
            requested_zoom: 5,
          });
        }}>{t("High ROC")}</p>
       <p className={filter.blockage ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
         setSelectedDevice(null);
         setFilterState({ ...filter, blockage: !filter.blockage });
         setRecoil(mapStateAtom, {
           ...getRecoil(mapStateAtom),
           required_update: true,
           requested_zoom: 5,
         });
       }}>{t("Blockage")}</p>
        <p className={filter.highWarningLevel ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
                  setSelectedDevice(null);
                  setFilterState({ ...filter, highWarningLevel: !filter.highWarningLevel });
                  await device_controller.get_device_list();
                  setRecoil(mapStateAtom, {
                    ...getRecoil(mapStateAtom),
                    required_update: true,
                    requested_zoom: 5,
                  });
                }}>{t("High Level")}</p>
                {[...systemTypes.filter(label => label), ...company.labels.filter(label => !systemTypes.includes(label) && !alertLabels.includes(label) && label)].map((label) => { 
            return <p key={label} className={filter.labels.includes(label) ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
              setFilterDropdown(false);
              setSelectedDevice(null);
              if (filter.labels.includes(label)) {
                setFilterState({
                  ...filter,
                  labels: [...filter.labels].filter((item) => item !== label)
                })
              }
              else {
                setFilterState({
                  ...filter,
                  labels: [...filter.labels, label]
                })
              }
              await device_controller.get_device_list();
              setRecoil(mapStateAtom, {
              ...getRecoil(mapStateAtom),
              required_update: true,
              requested_zoom: 5,
          });
            }}>
              {t(label)}
            </p>
          })}
        </div>
              }
              </div>
                <button className={css(button_styles.main_button)} style={{width: '8rem', margin: '0rem 0.5rem !important', padding: '0.4rem 0.7rem'}} onClick={async () => { 
                      resetFilter();
                      setRecoil(mapStateAtom, {
                        ...getRecoil(mapStateAtom),
                        required_update: true,
                        requested_zoom: 5,
                      });
                      await device_controller.get_device_list();
                      setSelectedDevice(null);
                      }}>{t("Clear Filter")}</button>
              </div>
            </div>
        
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((item) => (
                  <TableCell align='center' onClick={() => updateOrder(item.key)} sortDirection={sortDevicesBy === item.key ? sortDirection : false}>
                    <TableSortLabel
                      active={item.sort && sortDevicesBy === item.key}
                      hideSortIcon={!item.sort}
                      direction={sortDirection}
                      onClick={() => updateOrder(item.key)}
                      IconComponent={
                        () => <img src={sort_icon} alt="sort icon" className={css(deviceListComponentStyles.sort_icon)} />
                      }
                    >
                      <Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{t(item.label)}</Typography>
                    </TableSortLabel>
                  </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDevices.devices.slice().sort(getComparator(sortDirection, sortDevicesBy)).map((device) => {
                  return <TableRow key={device.device_id} onClick={() => {
                    if (!selectedDevice || selectedDevice.device_id !== device.device_id) {
                      device_controller.select_device(device.device_id)
                    }
                    }}>
                      <TableCell align='center' width="20%"><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{device.device_id}</Typography></TableCell>
                      <TableCell align='center' width="25%"><Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{device.current_level_percentage}</Typography></TableCell>
                      <TableCell align='center' width="30%">
                        <div className={css(label_styles.labels_container)}>
                        { device.labels.slice().sort(alphanumeric_comparator).map((label) => {
                            if (label === "Low battery" || label === "No messages" || alertLabels.includes(label)) {
                              if (device.labels.includes(label)) {
                                return <div className={css(label_styles.label_red)}>
                                  <Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{t(label)}</Typography>
                                </div>
                              }
                            }
                            return <div className={css(label_styles.label)}>
                              <Typography sx={{fontWeight: 600, fontfamily: '"Ubuntu", sans-serif'}}>{t(label)}</Typography>
                            </div>
                          })
                        }
                        </div>
                      </TableCell>
                      <TableCell align='center' width="25%">
                        <button className={device.pinned ? css(button_styles.main_button) : css(button_styles.main_button_disabled)} 
                          style={{flex: 1, alignItems: 'center', justifyContent: 'center', padding: '0.1rem 0.3rem', minWidth: '5rem' }}
                                  onClick={() => { device_controller.toggle_device_pin(device.device_id); }}
                        >
                          <Typography sx={{fontWeight: 600, fontfamily: '"ubuntu", sans-serif'}}>{device.pinned ? t("Y") : t("N")}</Typography>
                        </button>
                      </TableCell>
                    </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
          </article>
        });


import {
    atom,
} from 'recoil';

import { authStateInterface } from '../interfaces/auth_interfaces';

export const authStateAtom = atom<authStateInterface>({
    key : 'authState',
    default : {
        token : '',
        validToken : false,
        showInvalidCredWarning : false,
    }
});
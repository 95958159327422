export const primary_background_colour = '#ffffff';
export const header_footer_background_colour = '';
export const card_background_colour = 'lightgrey';


export const button_background_colour = '#000099';
export const disabled_button_background_colour = '#000099';
export const button_text_colour = '#ffffff';
export const button_font_size = '1.1rem';
export const button_font_weight = 500;


export const label_background_colour = '';
export const label_text_colour = '#000000';
export const label_border_colour = '#000099';

export const colors = {
  text_color: 'black',
  blue: "#000099",
  white: "#ffffff",
  light_grey: "#e3eefa",
  dark_grey: "#616161",
  dark_blue: "#252c39",
  light_blue: "#60CAD4",
  light_orange: "#FCAA8E",
};

export const sizes = {
  header: 60,
  simInfo: 37
};
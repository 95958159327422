import {
    atom
} from 'recoil';

import { dashboardVersionInterface, mapStateInterface } from '../interfaces/ui_interfaces';
import { version } from 'os';

export const panelSizesAtom = atom<{
    hTop : string,
    hBottom : string,
    vLeft : string,
    vRight : string
}>({
    key : 'panelSizes',
    default : {
        hTop : '0px',
        hBottom : '0px',
        vLeft : '0px',
        vRight : '0px'
    }
})


export const navStateAtom = atom<'device-metrics' | 'accounts-page' | 'settings-page'>({
    key : 'navState',
    default : 'device-metrics'
})

export const mapStateAtom = atom<mapStateInterface>({
    key: 'mapState',
    default : {
        requested_centre : [-3, 53],
        requested_zoom : 5,
        required_update : false
    }
})

export const dashboardVersionAtom = atom<dashboardVersionInterface>({
    key: 'dashboardVersion',
    default: {
        version: '2.0.0'
    },
})

export const languageAtom = atom<string>({
    key: 'language',
    default: 'en',
})
import './App.css';
import { css } from 'aphrodite';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'reactstrap';
import { authStateAtom } from './models/atoms/auth_atoms';
import { accountCompanyInfoAtom, userAccountAtom } from './models/atoms/account_atoms';
import { languageAtom, navStateAtom, dashboardVersionAtom } from './models/atoms/ui_atoms';
import { memo } from "react";
import { device_controller } from './controllers/device_controller';
import { auth_controller } from './controllers/auth_controller';
import { account_controller } from './controllers/account_controller';
import { company_controller } from './controllers/company_controller';
import { sizes } from './views/styles/style_variables'
import { LoginPage } from './views/pages/login'
import { DeviceMetricsPage } from './views/pages/device_metrics';
import { AccountsPage } from './views/pages/accounts';
import { SettingsPage } from './views/pages/settings';
import { appStyles } from './views/styles/app_styles';
import { TranslationProvider, useTranslation } from './contexts/TranslationContext'; // Ensure this is imported
// @ts-ignore
import  worldIcon from './assets/world-icon.png'
// @ts-ignore
import germany from './assets/germany.png'
// @ts-ignore
import uk from './assets/uk.png'
import { dashboardVersionInterface } from './models/interfaces/ui_interfaces';
const logo = require("./assets/logo.png") as string;

export const MainPage = memo(() => {
  const [navState, setNavState] = useRecoilState<'device-metrics' | 'accounts-page' | 'settings-page'>(navStateAtom);
  const account = useRecoilValue(userAccountAtom);
  const company_info = useRecoilValue(accountCompanyInfoAtom);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - sizes.header;
  const pageHeight = ((Math.floor(vh) - sizes.header).toString() + "px");
  const { t, changeLanguage, language } = useTranslation(); // Use the translation hook
  const [showDropdown, setShowDropdown] = useState(false);
  const dashboardVersion = useRecoilValue<dashboardVersionInterface>(dashboardVersionAtom);
  const [languageState, setLanguageState] = useRecoilState<string>(languageAtom);
  const languageMenu = useRef<HTMLInputElement>(null);

const toggleDropdown = () => {
  setShowDropdown(!showDropdown);
};

  useEffect(() => {
    changeLanguage(languageState)
  }, [languageState])

  const loadData: any = () => {
    company_controller.get_device_settings();
    company_controller.get_weathermode_settings();
    company_controller.get_roc_settings();
    company_controller.get_low_power_settings();
  };

  var page = <></>;

  const toggleLanguage = (lang:string) => {
    changeLanguage(lang);
  };

  const disableDropdown = (e: any) => {
    if (languageMenu.current && !languageMenu.current.contains(e.target)) {
      setShowDropdown(false);
    }
  }

  document.addEventListener('mousedown', disableDropdown)

  switch (navState) {
    case 'device-metrics':
      page = <DeviceMetricsPage style={{ height: "100%" }}></DeviceMetricsPage>;
      break;
    case 'accounts-page':
      page = <AccountsPage style={{ height: "100%" }}></AccountsPage>;
      break;
    case 'settings-page':
      loadData();
      page = <SettingsPage style={{ height: "100%" }}></SettingsPage>;
  }

  return (
    <div className={css(appStyles.app_style)} style={{ height: "100vh", overflow: 'hidden' }}>
      <div style={{ position: 'relative' }}>
      <div>
        <img style={{ position: 'absolute', width: 55, paddingTop: 5, marginLeft: 15 }} src={logo} alt="Logo" onClick={() => setNavState('device-metrics')}></img>
        <div style={{ position: 'absolute', left: '6rem', color: 'white', paddingTop: 15, fontSize: '1.4rem' }}>   {company_info ? t('Welcome', { company: company_info.name }) : ''}
        </div>
      </div>
      
  <div className={css(appStyles.nav_bar_styles)}>
    <Button className={`${css(appStyles.nav_button_styles)} ${navState === 'device-metrics' && css(appStyles.current_page)}`} onClick={() => setNavState('device-metrics')}>{t('Devices')}</Button>
    {
      account != null && account.permissions.manage_accounts ?
        <Button className={`${css(appStyles.nav_button_styles)} ${navState === 'accounts-page' && css(appStyles.current_page)}`} onClick={() => setNavState('accounts-page')}>{t('Accounts')}</Button> :
        <></>
    }
    <Button className={`${css(appStyles.nav_button_styles)} ${navState === 'settings-page' && css(appStyles.current_page)}`} onClick={() => setNavState('settings-page')}>{t('Settings')}</Button>
    <Button className={css(appStyles.logout_button)} onClick={() => { auth_controller.logout(); window.history.pushState({}, '', '/'); }}>{t('Logout')}</Button>
    
    <img
      src={worldIcon}
      alt="World Icon"
      onClick={toggleDropdown}
      style={{
        cursor: 'pointer',
        width: '40px',
        height: '40px',
        display: 'inline-block',
        float: 'left',
        borderColor: '#aad8e2',
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 10,
        marginTop: 5
      }}
    />
  </div>
  {showDropdown && (
    <div
      style={{
        position: 'absolute',
        top: '100%', // Adjust this value
        right: '15px', // Adjust this value,
        backgroundColor: 'transaparent',
        // boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        // padding: '2%', // Adjust this value
        // zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={languageMenu}
    >
      <img
        src={germany}
        alt="German Flag"
        onClick={() => {
          toggleLanguage('de');
          toggleDropdown();
        }}
        style={{ cursor: 'pointer', width: '40px', height: '40px' }} // Adjust these values
      />
      <img
        src={uk}
        alt="UK Flag"
        onClick={() => {
          toggleLanguage('en');
          toggleDropdown();
        }}
        style={{ cursor: 'pointer', width: '40px', height: '40px', margin: '15%' }} // Adjust these values
      />
    </div>
  )}
</div>


      <div style={{ height: pageHeight }}>{page}</div>
      <div className={css(appStyles.footer_styles)}>
        <a href='https://device-service.manhole-metrics.com/redirect-to-privacy-and-cookies'>
          <p style={{ color: 'white', paddingTop: 15 }}>{t('Privacy + Cookies')}</p>
        </a>
        <i className="bi bi-dot"></i>
        <a href='https://device-service.manhole-metrics.com/redirect-to-terms-of-use'>
          <p style={{ color: 'white', paddingTop: 15 }}>{t('Terms Of Use')}</p>
        </a>
        <i className="bi bi-dot"></i>
        <p style={{ color: 'white', paddingTop: 15, paddingRight: 10 }}>{t('Support')}:</p>
        <a href={`mailto:mailto:support@manholemetrics.com`} className={css(appStyles.icons)}><i className="bi bi-envelope"></i></a>
        <i className="bi bi-dot"></i>
        <a href='https://wa.me/02080505393' className={css(appStyles.icons)}><i className="bi bi-whatsapp"></i></a>
        <i className="bi bi-dot"></i>
        <p style={{ color: 'white', paddingTop: 15, paddingRight: 10 }}>{t('Version') + ' ' + process.env.REACT_APP_VERSION}</p>
      </div>
    </div>
  );
});

const FilterStateComponent = memo(_ => {
  return <MainPage></MainPage>;
});

function App() {
  const auth = useRecoilValue(authStateAtom);
  if (!auth.validToken) {
    auth_controller.check_token();
  } else {
    device_controller.get_device_list();
    account_controller.get_company_name();
    account_controller.get_account_list();
    account_controller.get_user_account();
    company_controller.setup();
  }
  if (!auth.validToken) {
    return (
      <div className={css(appStyles.app_style)} style={{ height: "100vh" }}>
        <TranslationProvider>
          <LoginPage />
        </TranslationProvider>
      </div>
    );
  }
  return (
    <TranslationProvider>
      <FilterStateComponent></FilterStateComponent>
    </TranslationProvider>
  );
}

export default App;

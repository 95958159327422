import { StyleSheet } from 'aphrodite';

export const login_page_styles = StyleSheet.create({
    main_container: {
        height: '65%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        width: '30rem',
        margin: '2rem 0 0 0'
    },
    login_form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    forgot_password_form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        width: '20rem',
        height: '2.5rem',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        border: '1px solid lightgrey',
        color: 'black',
        padding: '0.4rem',
        marginBottom: '0.5rem'
    },
    forgot_password_button: {
        border: 'none',
        backgroundColor: 'transparent',
        color: 'grey'
    },
    validation_error: {
        color: 'red',
        fontSize: '1rem',
        margin: '0.5rem 0'
    }
})

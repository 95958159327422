import React from 'react';
import { memo } from "react";
import { useRecoilValue } from "recoil";
import { newAccountModalAtom, modifyAccountModalAtom, deleteModalAtom } from "../../models/atoms/account_atoms";
import '../../App.css';
import { AccountListComponent } from "../components/account/AccountList";
import { NewAccountModalComponent, ModifyAccountModalComponent, DeleteModalComponent } from "../components/account/AccountModals";

export const AccountsPage = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const newAccountModal = useRecoilValue(newAccountModalAtom);
    const deleteAccountModal = useRecoilValue(deleteModalAtom);
    const modifyAccountModal = useRecoilValue(modifyAccountModalAtom);

    return <>
        {newAccountModal && <NewAccountModalComponent></NewAccountModalComponent>}
        {modifyAccountModal && <ModifyAccountModalComponent></ModifyAccountModalComponent>}
        {deleteAccountModal && <DeleteModalComponent></DeleteModalComponent>} 

        <AccountListComponent></AccountListComponent>
    </>
});
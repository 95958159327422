import { StyleSheet } from 'aphrodite';
import { button_background_colour, button_font_size, button_font_weight, button_text_colour, primary_background_colour } from './style_variables';

export const deviceInfoPanelStyles = StyleSheet.create({
  logo_container: {
    width: '100%', 
    height: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  logo: {
    width: '80%',
    height: 'auto'
  },
  panel_container: {
    width: '100%',
    height: '100%',
    padding: '1rem',
    borderRadius: 5,
    backgroundColor: primary_background_colour,
    overflowY: 'scroll',
  },
  panel_heading: {
    fontSize: '1.8rem',
    fontWeight: 600,
    textAlign: 'left',
    color: '#000099',
  },
  section_heading: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginTop: '1.6rem',
    color: '#000099'
  },
  subsection_text: {
    fontSize: '1.1rem',
    fontWeight: 400,
    margin: 0,
    marginBottom: '0.3rem'
  },
  link: {
    fontSize: '1rem',
    fontWeight: 400,
    color: 'purple', 
    borderBottom: '2px solid purple', 
    margin: 0,
    marginBottom: '0.3rem'
  },
  comments: {
    borderColor: '#CFD2CF !important',
    borderWidth: 1,
    borderRadius: 4,
    width: '100%',
    padding: '0.5rem',
    height: '6rem',
    fontSize: '1.1rem'
  },
  high_warning_level: {
    color: 'rgb(0, 0, 153)',
    fontWeight: 500,
  },
  warning_level_input: {
    border: 0,
    outline: 0,
    background: 'transparent',
    borderBottom: '1px solid #D3D3D3',
    width: '20px',
    fontSize: '1.1rem'
  },
  error_text: {
    color: 'red',
    fontSize: '14px',
    margin: '2px 0',
    fontWeight: 300
  },
  disclaimer: {
    fontSize: '1rem',
    color: 'grey',
    margin: '2px 0',
    fontWeight: 300
  },
  chart: {
    fontSize: '1rem',
    fontWeight: 300,
    margin: 0,
    padding: 0,
    left: '-30px'
  },
  gallery: {
    display: 'flex',
    flex: 1,
    overflow: 'auto'
  },
  periodButtonRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '27rem',
  },
  periodButtonSelected: {
    display: 'flex',
    width: '5rem',
    height: '2rem',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: 5,
    border: 'none',
    backgroundColor: button_background_colour,
    fontSize: button_font_size,
    fontWeight: button_font_weight,
    color: button_text_colour,
  },
  periodButton: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginHorizontal: 30,
    border: 'none',
    backgroundColor: 'lightgrey',
    fontSize: button_font_size,
    fontWeight: button_font_weight,
    color: button_text_colour,
    width: '5rem',
    height: '2rem',
  },
  arrowButton: {
    borderRadius: 5,
    border: 'none',
    backgroundColor: button_background_colour,
    fontSize: button_font_size,
    fontWeight: button_font_weight,
    color: button_text_colour,
  },
});

export const deviceListComponentStyles = StyleSheet.create({ 
  card: {
    backgroundColor: primary_background_colour,
    borderRadius: 5,
    margin: 15,
    padding: '0.5rem 1rem',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  search_filter_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0rem 1.7rem',
    zIndex: 10, 
  },
  search: {
    width: '40%',
    height: '2rem',
    backgroundColor: primary_background_colour,
    border: '1px solid lightgrey',
    borderRadius: 5,
    color: 'grey',
    padding: 5,
  },
  filter_buttons_container: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 300
  }, 
  header_row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '3rem',
    borderBottom: '2px solid lightgrey',
  },
  header_row_item: {
    fontWeight: 600,
    fontSize: '1rem',
    width: '10rem',
    margin: '5px 12px',
    textAlign: 'center',
    maxWidth: '15rem',
    paddingLeft: '10px'
  },
  device_list: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100%',
    padding: 0
  },
  device_list_row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    borderBottom: '2px solid lightgrey',
    justifyContent: 'center',
    minHeight: '5rem',
  },
  device_list_row_selected: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    minHeight: '5rem',
    justifyContent: 'space-evenly',
    backgroundColor: '#f1f1f1',
    borderBottom: '2px solid lightgrey',
    borderRadius: 5,
  },
  device_list_row_item: {
    display: 'flex',
    flex: 0.25,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
    width: '25%',
  },
  sort_icon: {
    position: 'absolute',
    right: '-20px',
    width: '1.5rem',
    height: 'auto',
    padding: 2,
    cursor: 'pointer',
    float: 'right'
  }
})

export const fleetMetricsStyles = StyleSheet.create({
  fleet_metrics_container: {
    maxWidth: '8em', 
    maxHeight: '95%',
    position: 'absolute', 
    margin: 5, 
    right: 5, 
    top: 5, 
    backgroundColor: 'white', 
    borderRadius: 5, 
    flex: 1,
    flexDirection: 'column', 
    padding: 10, 
    overflowY: 'scroll', 
    overflowX: 'hidden',
    textAlign: 'center'
  },
  fleet_metrics_icon: {
    width: '2.4rem', 
    height: 'auto', 
    padding: 2
  },
  fleet_metrics_value: {
    fontSize: 24, 
    color: 'black', 
    margin: 5, 
  },
  fleet_metrics_icon_value_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
  },
  hover_info: {
    position: 'absolute',
    right: '17%',
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 5,
    border: '2px solid lightgrey',
    color: 'black',
    fontSize: 12
  }
})
import { authStateAtom } from '../models/atoms/auth_atoms';
import { companyDeviceSettingsAtom, companyHighToLowCounterAtom, companyWeathermodeSettingsAtom, companyLowPowerSettingsAtom, labelsAtom, companyROCSettingsAtom } from '../models/atoms/company_atoms';
import { getRecoil, setRecoil } from "recoil-nexus";
import { companyDeviceSettings, companyHighToLowCounter, companyWeathermodeSettings, patchCompanyDeviceSettings, patchCompanyROCSettings, patchCompanyWeathermodeSettings, companyLowPowerSettings, patchCompanyLowPowerSettings, companyROCSettings } from '../models/interfaces/company_interfaces';

const device_link = process.env.REACT_APP_DEVICE_API_URL;

export const company_controller = {

    _get_labels: async function () {
      const obj = {
        link: device_link + '/company/labels',
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      const response = await fetch(obj.link, obj.object);
      if (response.status !== 200) {
        return;
      }
      const labels = await response.json() as string[];
      setRecoil(labelsAtom, { labels: labels });
  
    },
  
    remove_device_label: async function (device_id: string, label: string) {
      var obj = {
        link: device_link + '/company/device_label?' + new URLSearchParams({
          label: label,
          device_id: device_id,
        }),
        object: {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      await fetch(obj.link, obj.object);
    },
  
    add_device_label: async function (device_id: string, label: string) {
      var obj = {
        link: device_link + '/company/device_label?' + new URLSearchParams({
          label: label,
          device_id: device_id,
        }),
        object: {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      await fetch(obj.link, obj.object);
    },
  
    create_label: function (label: string) {
      var obj = {
        link: device_link + '/company/label?' + new URLSearchParams({
          label: label,
        }),
        object: {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(() => this._get_labels());
    },
  
    delete_label: function (label: string) {
      var obj = {
        link: device_link + '/company/label?' + new URLSearchParams({
          label: label,
        }),
        object: {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(() => this._get_labels());
    },
  
    get_device_settings: function () {
      const obj = {
        link: device_link + '/company/device_settings',
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyDeviceSettings) // TODO add bad response handling
        .then(settings => setRecoil(companyDeviceSettingsAtom, settings));
    },
  
    get_weathermode_settings: function () {
      const obj = {
        link: device_link + '/company/weathermode_settings',
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyWeathermodeSettings) // TODO add bad response handling
        .then(settings => setRecoil(companyWeathermodeSettingsAtom, settings));
    },
  
    modify_company_weathermode_settings: async function (
      weathermode_on: boolean | null = null,
      rainfall_level_to_warn_mm: number | null = null
    ) {
      var obj = {
        link: device_link + '/company/weathermode_settings',
        object: {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
            'Content-type': 'application/json'
          },
          body: JSON.stringify(
            {
              weathermode_on: weathermode_on,
              rainfall_level_to_warn_mm: rainfall_level_to_warn_mm,
            } as patchCompanyWeathermodeSettings
          )
        }
      };
      await fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyWeathermodeSettings)
        .then(settings => setRecoil(companyWeathermodeSettingsAtom, settings));
    },

    get_roc_settings: function () {
      const obj = {
        link: device_link + '/company/roc_settings',
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyROCSettings) // TODO add bad response handling
        .then(settings => setRecoil(companyROCSettingsAtom, settings));
    },

    modify_company_roc_settings: async function (
      roc_mode_on: boolean | null = null,
      roc_threshold: number | null = null
    ) {
      var obj = {
        link: device_link + '/company/roc_settings',
        object: {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
            'Content-type': 'application/json'
          },
          body: JSON.stringify(
            {
              roc_mode_on: roc_mode_on,
              roc_threshold: roc_threshold,
            } as patchCompanyROCSettings
          )
        }
      };
      await fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyROCSettings)
        .then(settings => setRecoil(companyROCSettingsAtom, settings));
    },

    get_low_power_settings: function () {
      const obj = {
        link: device_link + '/company/low_power_settings',
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyLowPowerSettings) // TODO add bad response handling
        .then(settings => setRecoil(companyLowPowerSettingsAtom, settings));
    },

    modify_company_low_power_settings: async function (
      low_power_on: boolean | null = null,
      low_power_battery_threshold: number | null = null
    ) {
      var obj = {
        link: device_link + '/company/low_power_settings',
        object: {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
            'Content-type': 'application/json'
          },
          body: JSON.stringify(
            {
              low_power_on: low_power_on,
              low_power_battery_threshold: low_power_battery_threshold,
            } as patchCompanyLowPowerSettings
          )
        }
      };
      await fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyLowPowerSettings)
        .then(settings => setRecoil(companyLowPowerSettingsAtom, settings));
    },

    modify_high_to_low_counter: async function (
      counter: number
    ) {
      var obj = {
        link: device_link + '/company/high_to_low_counter?new_counter=' + counter,
        object: {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
            'Content-type': 'application/json'
          },
        }
      };
      await fetch(obj.link, obj.object)
        .then(() => this.get_high_to_low_counter());
    },

    get_high_to_low_counter: async function () {
      const obj = {
        link: device_link + '/company/high_to_low_counter',
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      await fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyHighToLowCounter)
        .then(new_counter => setRecoil(companyHighToLowCounterAtom, new_counter)).catch((e) => {
          console.log(e);
          return
        })
    },
  
    modify_device_settings: async function (
      message_wait_time_s: number | null = null,
      measurement_sleep_time_s: number | null = null,
      warning_message_wait_time_s: number | null = null,
      warning_measurement_sleep_time_s: number | null = null,
      weathermode_message_wait_time_s : number | null = null,
      weathermode_measurement_sleep_time_s : number | null = null,
      low_power_message_wait_time_s : number | null = null,
      low_power_measurement_sleep_time_s : number | null = null,
    ) {
      var obj = {
        link: device_link + '/company/device_settings',
        object: {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
            'Content-type': 'application/json'
          },
          body: JSON.stringify(
            {
              message_wait_time_s: message_wait_time_s,
              measurement_sleep_time_s: measurement_sleep_time_s,
              warning_message_wait_time_s: warning_message_wait_time_s,
              warning_measurement_sleep_time_s: warning_measurement_sleep_time_s,
              weathermode_message_wait_time_s : weathermode_message_wait_time_s,
              weathermode_measurement_sleep_time_s : weathermode_measurement_sleep_time_s,
              low_power_message_wait_time_s : low_power_message_wait_time_s,
              low_power_measurement_sleep_time_s : low_power_measurement_sleep_time_s,
            } as patchCompanyDeviceSettings
          )
        }
      };
      await fetch(obj.link, obj.object)
        .then(async response => await response.json() as companyDeviceSettings)
        .then(settings => setRecoil(companyDeviceSettingsAtom, settings));
    },
  
    setup: function () {
      this._get_labels();
      this.get_device_settings()
    },
  }
import {
    atom
} from 'recoil';

import { companyDeviceSettings, companyROCSettings, companyWeathermodeSettings, companyLowPowerSettings, changeLowPowerSettingsInterface, changeDeviceSettingsInterface, companyHighToLowCounter, changeWeathermodeSettingsInterface, changeROCSettingsInterface } from '../interfaces/company_interfaces';



export const companyDeviceSettingsAtom = atom<companyDeviceSettings|null>({
    'key' : 'companyDeviceSettingsState',
    default : null
});

export const companyWeathermodeSettingsAtom = atom<companyWeathermodeSettings|null>({
    'key' : 'companyWeathermodeSettingsState',
    default : null
});

export const companyROCSettingsAtom = atom<companyROCSettings|null>({
    'key' : 'companyROCSettingsState',
    default : null
});

export const companyLowPowerSettingsAtom = atom<companyLowPowerSettings|null>({
    'key' : 'companyLowPowerSettingsState',
    default : null
});


export const companyHighToLowCounterAtom = atom<companyHighToLowCounter|null>({
    'key' : 'companyHighToLowCounter',
    default : null
})

export const labelsAtom = atom<{labels : string[]}>({
    'key' : 'labelsState',
    default : {
        labels : [],
    }
});

export const changeCompanyDeviceAtom = atom<changeDeviceSettingsInterface | null>({
    key: 'changeCompanyDeviceAtom',
    default: null
})

export const changeWeathermodeAtom = atom<changeWeathermodeSettingsInterface | null>({
    key: 'changeWeathermodeAtom',
    default: null
})

export const changeROCAtom = atom<changeROCSettingsInterface | null>({
    key: 'changeROCAtom',
    default: null
})

export const changeLowPowerAtom = atom<changeLowPowerSettingsInterface | null>({
    key: 'changeLowPowerAtom',
    default: null
})

export const deleteLabelModalAtom = atom<string> ({
    key: 'deleteLabelModalAtom',
    default: ''
})
import { StyleSheet } from 'aphrodite';

export const accountsPageStyle = StyleSheet.create({
    form_title: {
      fontSize: '1.2em',
      fontWeight: 400
    },
    main_style: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    row_style: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginBottom: 10
    },
    create_new_style: {
        backgroundColor: '#277BC0',
        boxShadow: 0,
        borderColor: '#277BC0',
        marginRight: 5,
        width: '15em',
        height: '3em',
        fontWeight: 'bold',
    },
    break_style: {
        flexBasis: '100%',
        height: 0
    },
    account_button_style: {
        height: '3em',
        fontSize: 18,
        width: '33em',
        marginLeft: 0,
        marginRight: 0,
        background: 'rgb(91, 192, 248) !important',
        borderColor: 'transparent',
        marginBottom: 20,
        textAlign: 'left',
        color: 'black',
        fontWeight: 500
    },
    search_style: {
        width: '78%',
        height: '3em',
        paddingRight: '10em',
        marginLeft: '0em',
        marginRight: '2em',
    },
    delete_button_style: {
        backgroundColor: "#c4120c",
        borderColor: "#c4120c",
        marginTop: 15,
        width: '5em',
        fontWeight: 500
    },
    submit_button_style: {
        marginTop: 10,
        width: '5em',
        fontWeight: 500
    },
    cancel_button_style: {
        backgroundColor: "#B4B4B4",
        borderColor: "#B4B4B4",
        width: '100%',
        fontWeight: 500
    },
    confirm_delete_button_style: {
        backgroundColor: "#c4120c",
        borderColor: "#c4120c",
        width: '100%',
        fontWeight: 500
    },
    delete_model_style: {
        width: '300px',
        height: '300px',
        position: 'absolute',
        left: ' 50%',
        top: '50%',
        marginLeft: '-150px',
        marginTop: '-150px',
        textAlign: 'center'
    },
    delete_model_header: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    link: {
      color: "#000099",
      textDecoration: 'underline',
    },
  });

export const accountListComponentStyles = StyleSheet.create({
    main_container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    accounts_title: {
      fontSize: '1.8rem',
      fontWeight: 600
    },
    account_list_card: {
      backgroundColor: 'white',
      height: '70%',
      width: '90%',
      borderRadius: 5,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    }, 
    search_add_button_container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '90%',
      margin: '1rem 0'
    },
    search: {
      width: '75%',
      height: '3rem',
      backgroundColor: '#ffffff',
      border: '1px solid lightgrey',
      borderRadius: 5,
      color: 'black',
      padding: '0 1rem'
    },
    header_row: {
      display: 'flex',
      flexDirection: 'row',
      listStyleType: 'none',
      width: '100%',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      margin: '0',
      padding: '0.7rem 0',
      borderBottom: '2px solid lightgrey',
      minHeight: '5rem'
    },
    header_row_item: {
      fontWeight: 600,
      fontSize: '1rem',
      minWidth: '10rem',
      margin: '5px 12px',
      textAlign: 'center'
    },
    account_list: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      padding: 0
    },
    account_list_row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      borderBottom: '2px solid lightgrey',
      minHeight: '5rem',
      overflow: 'hidden'
    },
    account_list_row_selected: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      borderBottom: '2px solid lightgrey',
      minHeight: '5rem',
      overflow: 'hidden',
      backgroundColor: '#f1f1f1',
      borderRadius: 5,
    },
    account_list_row_item: {
      flex: 1,
      padding: '0.5rem',
      minWidth: '12.6rem',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '1rem',
      marginLeft: 15
    },
  })
import React,{ createContext, useState, useContext, useEffect, ReactNode } from 'react';
import en from '../Locales/en.json';
import de from '../Locales/de.json';


interface Translations {
  [key: string]: string;
}

interface TranslationContextType {
  t: (key: string, values?: Record<string, string>) => string;
  changeLanguage: (lang: string) => void;
  language: string;
}

const translations: Record<string, Translations> = {
  en,
  de,
};

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>('en');
  const [currentTranslations, setCurrentTranslations] = useState<Translations>(translations.en);

  useEffect(() => {
    setCurrentTranslations(translations[language]);
  }, [language]);

  const t = (key: string, values?: Record<string, string>): string => {
    let translation = currentTranslations[key] || key;
    if (values) {
      for (let placeholder in values) {
        translation = translation.replace(`{${placeholder}}`, values[placeholder]);
      }
    }
    return translation;
  };

  const changeLanguage = (lang: string): void => {
    setLanguage(lang);
  };

  return (
    <TranslationContext.Provider value={{ t, changeLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = (): TranslationContextType => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};

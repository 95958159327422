import React, { useState } from 'react';
import { memo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { changeUserAtom, textAlertErrorAtom } from "../../../models/atoms/account_atoms";
import { changeCompanyDeviceAtom } from "../../../models/atoms/company_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { company_controller } from "../../../controllers/company_controller";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext';
import {
    Button,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
} from "reactstrap";
/* eslint-disable no-useless-escape */

export const AlertSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const [changeUser, setChangeUser] = useRecoilState(changeUserAtom);
    const times = useRecoilValue(changeCompanyDeviceAtom);
    const [textAlertError, setTextAlertError] = useRecoilState(textAlertErrorAtom);
    const { t } = useTranslation();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

    const saveAlerts = () => {
        const button = document.getElementById("save_alerts");
        if (button) {
            button.click();
        }
    }

    const handleSaveButtonClick = () => {
        if (changeUser?.email_alerts || changeUser?.text_alerts) {
            setIsSaveModalOpen(true);
            account_controller.modify_user_account({ text_alerts: changeUser.text_alerts, email_alerts: changeUser.email_alerts });
        } else {
            // Save the alert settings without opening the modal
            if (changeUser) {
                account_controller.modify_user_account({ text_alerts: changeUser.text_alerts, email_alerts: changeUser.email_alerts });
            }
        }
    }

    return (
        <form id='alert' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
            e.preventDefault();
            if (times === null) { return; }
            company_controller.modify_device_settings(times?.message_wait_time_s, times?.measurement_sleep_time_s, times?.warning_message_wait_time_s, times?.warning_measurement_sleep_time_s)
        }}>
            <h2 className={css(SettingsPageStyles.card_title)}>{t('Alerts')}</h2>
            <div className={css(SettingsPageStyles.settings_card_body)}>
                <h3 style={{ fontSize: '1.2rem' }}>{t('Control settings for notifications.')}</h3>
                <p className={css(SettingsPageStyles.disclaimer)}>{t('The limit is one email and/or SMS notification per alert type per sensor per day.')}</p>
                <p className={css(SettingsPageStyles.disclaimer)}>{t('Notifications are for the following alerts: high warning level, high ROC (if mode engaged), low battery and predictions for blockages, floods and silt build-ups.')}</p>

                <div style={{ display: "flex" }}>
                    <label className="switch" style={{ marginRight: '0.5rem' }}>
                        <input type="checkbox" onChange={(e) => {
                            if (changeUser == null) { return; }
                            setChangeUser({
                                ...changeUser,
                                email_alerts: e.target.checked,
                                change_alerts: true
                            })
                        }} checked={changeUser == null ? false : changeUser.email_alerts} />
                        <span className="slider round"></span>
                    </label>
                    <p>{t('Email')}</p>
                </div>

                <div style={{ display: "flex" }}>
                    <label className="switch" style={{ marginRight: '0.5rem' }}>
                        <input type="checkbox" onChange={(e) => {
                            if (changeUser == null) { return; }
                            if (!changeUser.phone_number || changeUser.phone_number === 'Na' || changeUser.phone_number.length < 10 || !Number(changeUser.phone_number)) {
                                setTextAlertError(t("Please provide a valid phone number in your account settings in order to receive text alerts!"));
                                return
                            }
                            setChangeUser({
                                ...changeUser,
                                text_alerts: e.target.checked,
                                change_alerts: true
                            })
                        }} checked={changeUser == null ? false : changeUser.text_alerts} />
                        <span className="slider round"></span>
                    </label>
                    <p>{t('SMS')}</p>
                </div>

                {textAlertError && <p className="password-alert">{textAlertError}</p>}
                <button
                    className={css(changeUser?.change_alerts ? button_styles.main_button : button_styles.main_button_disabled)}
                    style={{ padding: '0.2rem 1rem', margin: '1rem 0', minWidth: '5rem' }}
                    type="button"
                    onClick={handleSaveButtonClick}
                >
                    {t('Save')}
                </button>

                <Modal isOpen={isSaveModalOpen} toggle={() => setIsSaveModalOpen(!isSaveModalOpen)}>
                    <ModalHeader toggle={() => setIsSaveModalOpen(false)}>{t('Alert Settings Saved')}</ModalHeader>
                    <ModalBody>
                    <p style={{ whiteSpace: 'pre-line' }}>
  {t("An email and/or SMS have been sent to the details\nprovided, please check your spam and ensure that alerts@manholemetrics.com is added to your contacts\n or safe sender list to prevent classification of alerts as spam.")}
</p>
                    </ModalBody>
                </Modal>
            </div>
        </form>
    );
});


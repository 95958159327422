import React from 'react';
import { memo, useState } from "react";
import { useRecoilState } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { changeCompanyDeviceAtom, changeWeathermodeAtom } from "../../../models/atoms/company_atoms";
import { company_controller } from "../../../controllers/company_controller";
import { toHour, toMinute } from "../../../utils/helper_functions";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { useTranslation } from '../../../contexts/TranslationContext';
/* eslint-disable no-useless-escape */

export const WeatherModeSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const [times, setTimes] = useRecoilState(changeCompanyDeviceAtom);
    const [weathermodeSettings, setWeathermodeSettings] = useRecoilState(changeWeathermodeAtom);
    const [weathermodeRelayError, setWeathermodeRelayError] = useState('');
    const [weathermodeReadingError, setWeathermodeReadingError] = useState('');
    const [rainfallThresholdError, setRainfallThresholdError] = useState('');
    const [weathermodeTimesChanged, setWeathermodeTimesChanged] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    if (!times) {
        return <></>
    }

    return <form id='weather_mode' className={css(SettingsPageStyles.settings_card)} onSubmit={ (e) => {
            e.preventDefault();
            if (weathermodeSettings ==  null) { return; }
            company_controller.modify_company_weathermode_settings(weathermodeSettings?.weathermode_on, weathermodeSettings?.rainfall_level_to_warn_mm).then((resp) => {
                company_controller.modify_device_settings(null, null, null, null, times?.weathermode_message_wait_time_s, times?.weathermode_measurement_sleep_time_s);
            })}}>
        
            <h2 className={css(SettingsPageStyles.card_title)}>{t('Weather Mode')}</h2>
            <div className={css(SettingsPageStyles.settings_card_body)}>
            <div style={{display: "flex", width: '10rem', justifyContent: 'space-between'}}>
                <label className="switch">
                <input type="checkbox" checked={weathermodeSettings === null ? false : weathermodeSettings.weathermode_on} onChange={async (e) => {
                    if (weathermodeSettings === null) { return; }
                    setWeathermodeSettings({
                        ...weathermodeSettings,
                        weathermode_on: e.target.checked,
                        change_weathermode: true
                    });
                }}/>
                <span className="slider round"></span>
                </label>
                <p style={{fontWeight: 500}}>{t('Weather Mode')}</p>
            </div>
            <p className={css(SettingsPageStyles.disclaimer)}>
            {t("Weather Mode monitors forecast rainfall in each device's location. Each time a device relays data, the system checks the forecasted hourly rainfall for the next 24 hours with the maximum hourly threshold configured below. If the maximum hourly rainfall for the next 24 hours exceeds the threshold, the device frequencies are updated to those defined below, until the maximum hourly rainfall for the next 24 hours is checked (each time the device relays data) and is found to fall below the threshold. These settings ensure that not only battery life is conserved, as less frequent Normal Operating frequencies can be selected, but that there is also sufficient flow of data during high risk rainfall events to provide network insights.")}
            </p>
            {weathermodeSettings?.weathermode_on &&
            <section className={css({...SettingsPageStyles.settings_card_sections_container, width: '100% !important', marginBottom: 0})} style={{width: '100%', marginTop: '2rem'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                    <label className={css(SettingsPageStyles.card_input_label)}>{t('Rainfall Threshold (per hour):')}</label>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                    <input className={css(SettingsPageStyles.card_input)} type="text" defaultValue={weathermodeSettings?.rainfall_level_to_warn_mm} onChange={(e) => {
                            setRainfallThresholdError('');
                            if (weathermodeSettings == null) { return; }
                            if (!(/^[0-9.]+$/).test(e.target.value)) {
                                setRainfallThresholdError(t("Please provide a valid number!"));
                            }
                            else if (Number(e.target.value) < 2 || Number(e.target.value) > 20) {
                                setRainfallThresholdError(t("Not in the allowable range of [2, 20] millimeters"))
                            }
                            setWeathermodeSettings({ ...weathermodeSettings, rainfall_level_to_warn_mm: Number(e.target.value), change_weathermode: true })
                        }}/> <span style={{marginRight: '1rem'}}>{t('Millimeters')}</span>
                    </div>
                    <p className={css(SettingsPageStyles.error)}>{rainfallThresholdError}</p>
                </div>

            <div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <label className={css(SettingsPageStyles.card_input_label)}>{t('Relay Frequency:')}</label>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <input className={css(SettingsPageStyles.card_input)} type="text" style={{minWidth: '10rem', marginRight: '0.5rem'}} defaultValue={toHour(times.weathermode_message_wait_time_s)} onChange={(e) => {
                        setWeathermodeRelayError('');
                        if (times == null) { return; }
                        if (!(/^[0-9.]+$/).test(e.target.value)) {
                            setWeathermodeRelayError(t("Please provide a valid number!"));
                        }
                        else if (Number(e.target.value) * 3600 < 720 || Number(e.target.value) * 3600 > (72 * 60 * 60)) {
                            setWeathermodeRelayError(t('Not in the allowable range of [0.2, 72] hours'));
                        }
                        else {
                            setTimes({ ...times, weathermode_message_wait_time_s: (Number(e.target.value) * 3600), change_time: true });
                            setWeathermodeTimesChanged(true)
                        }
                    }}/> <span style={{marginRight: '1rem'}}>{t('Hours')}</span>
                </div>
                <p className={css(SettingsPageStyles.error)}>{weathermodeRelayError}</p>
            </div>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <label className={css(SettingsPageStyles.card_input_label)}>{t('Sensor Reading Frequency:')}</label>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <input className={css(SettingsPageStyles.card_input)} type="text" style={{minWidth: '10rem', marginRight: '0.5rem'}} defaultValue={toMinute(times.weathermode_measurement_sleep_time_s)} onChange={(e) => {
                        setWeathermodeReadingError('');
                        if (times == null) { return; }
                        if (!(/^[0-9.]+$/).test(e.target.value)) {
                            setWeathermodeReadingError(t("Please provide a valid number!"));
                        }
                        else if (Number(e.target.value) * 60 < 300 || Number(e.target.value) * 60 > (720 * 60)) {
                            setWeathermodeReadingError(t('Not in the allowable range of [5, 720] minutes'));
                        }
                        else {
                            setTimes({ ...times, weathermode_measurement_sleep_time_s: (Number(e.target.value) * 60), change_time: true })
                            setWeathermodeTimesChanged(true)
                        }
                    }}/> <span style={{marginRight: '1rem'}}>{t('Minutes')}</span>
                </div>
                <p className={css(SettingsPageStyles.error)}>{weathermodeReadingError}</p>
            </div>

        </div>
        </section>}
    
        <p className={css(SettingsPageStyles.disclaimer)}>{t('Please note that weather mode settings are applied to all of the devices in the company\'s fleet. These settings are communicated to the devices the next time they transmit data.')}</p>
        <Button
  className={css((weathermodeSettings?.change_weathermode || weathermodeTimesChanged) && (!weathermodeRelayError && !weathermodeReadingError) ? button_styles.main_button : button_styles.main_button_disabled)} 
  style={{minWidth: '5rem', padding: '0.2rem 1rem', margin: '1rem 0rem'}}
  onClick={toggle}
  disabled={!((weathermodeSettings?.change_weathermode || weathermodeTimesChanged) && (!weathermodeRelayError && !weathermodeReadingError))}
>
  {t('Save')}
</Button>
<Modal isOpen={modal} toggle={toggle} className={css(SettingsPageStyles.delete_model_style)}>
  <ModalHeader toggle={toggle} style={{ textAlign: 'center' }} >{t('Confirm Settings')}</ModalHeader>
  <ModalBody>
    <Row>
      <legend style={{fontSize: '1.2em', color: 'red', textAlign: 'center'}}>{t('Are you sure you want to save these settings?')}</legend>
      <Col xs="6" className="d-flex">
        <Button className={css(SettingsPageStyles.cancel_button_style)} onClick={toggle}>
          {t('Cancel')}
        </Button>
      </Col>
      <Col xs="6" className="d-flex">
        <Button className={css(SettingsPageStyles.confirm_delete_button_style)} onClick={() => {
          toggle();
          if (weathermodeSettings ==  null) { return; }
          company_controller.modify_company_weathermode_settings(weathermodeSettings?.weathermode_on, weathermodeSettings?.rainfall_level_to_warn_mm).then((resp) => {
            company_controller.modify_device_settings(null, null, null, null, null, null, times?.weathermode_message_wait_time_s, times?.weathermode_measurement_sleep_time_s);
          })
        }}>
          {t('Save')}
        </Button>
      </Col>
    </Row>
  </ModalBody>
</Modal>
    </div>
</form>

});

import React from 'react';
import { memo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import '../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { userAccountAtom, changeUserAtom } from "../../models/atoms/account_atoms";
import { companyDeviceSettingsAtom, companyWeathermodeSettingsAtom, changeCompanyDeviceAtom, changeWeathermodeAtom, deleteLabelModalAtom, companyLowPowerSettingsAtom, changeLowPowerAtom, companyROCSettingsAtom, changeROCAtom } from "../../models/atoms/company_atoms";
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { getRecoil, setRecoil } from "recoil-nexus";
import { company_controller } from "../../controllers/company_controller";
import { SettingsPageStyles } from "../styles/settings_page_styles";
import { DeviceSettingsComponent } from "../components/settings/DeviceSettings";
import { LowPowerSettingsComponent } from '../components/settings/LowPowerSettings';
import { WeatherModeSettingsComponent } from "../components/settings/WeatherModeSettings";
import { LabelSettingsComponent } from "../components/settings/LabelSettings";
import { AccountSettingsComponent } from "../components/settings/AccountSettings";
import { AlertSettingsComponent } from "../components/settings/AlertSettings";
import { useTranslation } from '../../contexts/TranslationContext'; // Assuming the context is here
/* eslint-disable no-useless-escape */

export const SettingsPage = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    return <>
        <SettingsStateManager></SettingsStateManager>
        <SettingsPageUI></SettingsPageUI>
    </>
});

const SettingsStateManager = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const user = useRecoilValue(userAccountAtom);
    const company = useRecoilValue(companyDeviceSettingsAtom);
    const company_weathermode = useRecoilValue(companyWeathermodeSettingsAtom);
    const company_low_power = useRecoilValue(companyLowPowerSettingsAtom);
    const company_roc = useRecoilValue(companyROCSettingsAtom);

    console.log('change')
    if (user != null) {
        setRecoil(changeUserAtom, {
            ...user,
            change_alerts: false,
            change_email_phone: false,
            change_first_name: false,
            change_last_name: false
        });
        console.log(getRecoil(changeUserAtom))
    }
    if (company != null) {
        setRecoil(changeCompanyDeviceAtom, {
            ...company,
            change_time: false,
        });
    }
    if (company_weathermode != null) {
        setRecoil(changeWeathermodeAtom, {
            ...company_weathermode,
            change_weathermode: false,
        });
    }
    if (company_roc != null) {
        setRecoil(changeROCAtom, {
            ...company_roc,
            change_roc: false,
        });
    }
    if (company_low_power != null) {
        setRecoil(changeLowPowerAtom, {
            ...company_low_power,
            change_low_power: false,
        });
    }
    return <></>
});

const SettingsPageUI = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const times = useRecoilValue(changeCompanyDeviceAtom);
    const [deleteAccount, setDeleteAccount] = useRecoilState(deleteLabelModalAtom);

    if (!times) {
        return <></>
    }

    return <>
        <Modal isOpen={deleteAccount === '' ? false : true} className={css(SettingsPageStyles.delete_model_style)}>
            <ModalHeader className={css(SettingsPageStyles.delete_model_header)}>{t('Confirm Deletion')}</ModalHeader>
            <ModalBody>
                <Row>
                <legend style={{fontSize: '1.2em', color: 'red', textAlign: 'center'}}>{t('This label will be deleted from all devices')}</legend>
                    <Col xs="6" className="d-flex">
                        <Button className={css(SettingsPageStyles.cancel_button_style)}
                            onClick={() => { setDeleteAccount('')}}
                        >
                            {t('Cancel')}
                        </Button></Col>
                    <Col xs="6" className="d-flex">
                        <Button className={css(SettingsPageStyles.confirm_delete_button_style)}
                            onClick={() => { company_controller.delete_label(deleteAccount); setDeleteAccount('') }}
                        >
                            {t('Delete')}
                        </Button>
                    </Col>
                </Row>

            </ModalBody>
        </Modal>

        <SettingsStateManager></SettingsStateManager>
        <div className={css(SettingsPageStyles.main_container)}>
                {/** menu */}
            <nav className={css(SettingsPageStyles.menu)}>
                <h2 className={css(SettingsPageStyles.menu_title)}>{t('Shortcuts')}</h2>
                <a className={css(SettingsPageStyles.menu_link)} href="#frequencies">{t('Frequencies')}</a>
                <a className={css(SettingsPageStyles.menu_link)} href="#low_power_mode">{t('Low Power Mode')}</a>
                <a className={css(SettingsPageStyles.menu_link)} href="#weather_mode">{t('Weather Mode')}</a>
                <a className={css(SettingsPageStyles.menu_link)} href="#label">{t('Labels')}</a>
                <a className={css(SettingsPageStyles.menu_link)} href="#account">{t('Account')}</a>
                <a className={css(SettingsPageStyles.menu_link)} href="#alert">{t('Alerts')}</a>
            </nav>

            <div className={css(SettingsPageStyles.settings_cards_container)}>
                <DeviceSettingsComponent></DeviceSettingsComponent>
                <LowPowerSettingsComponent></LowPowerSettingsComponent>
                <WeatherModeSettingsComponent></WeatherModeSettingsComponent>
                <LabelSettingsComponent></LabelSettingsComponent>
                <AccountSettingsComponent></AccountSettingsComponent>
                <AlertSettingsComponent></AlertSettingsComponent>

        </div>
        </div>
    </>
});

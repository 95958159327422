/* eslint-disable no-useless-escape */
import '../../App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import {
  useRecoilValue,
} from 'recoil';
import { panelSizesAtom } from "../../models/atoms/ui_atoms";
import MapContainer from '../../utils/Map';
import React from 'react';
import { colors } from '../styles/style_variables';
import { FleetMetrics } from './FleetMetrics';




export const MapComponent = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const panel = useRecoilValue(panelSizesAtom);
  
    
  
    // @ts-ignore
    var height = (parseInt(panel.hTop.replace(/px/, "")) - 20) + "px";
    // @ts-ignore
    const width = (parseInt(panel.vLeft.replace(/px/, "")) - 30) + "px";
    

  

  
    return <div data-testid="Map Component" style={{ width: width, height: height, position: 'absolute', padding: 5,
    margin: 15,
    borderRadius: 3,
    backgroundColor: colors.white,
    borderColor: colors.white, }}>
        {/* @ts-ignore */}
      <MapContainer style={{ width: "100%", height: height }}>
     
      </MapContainer>
      <FleetMetrics></FleetMetrics>
      {/* <FleetMetrics></FleetMetrics> */}
     
    </div>;
  }



import {
    atom,
} from 'recoil';
import { accountListInterface, accountInterface, changeUserInterface, accountCompanyInfoInterface } from '../interfaces/account_interfaces';


export const userAccountAtom = atom<accountInterface|null>({
    key : 'userAccountState',
    default : null,
});

export const accountListStateAtom = atom<accountListInterface>({
    key: 'accountListState', // unique ID (with respect to other atoms/selectors)
    default: {
        accounts : []
    }, // default value (aka initial value)
});

export const accountCompanyInfoAtom = atom<accountCompanyInfoInterface|null>({
    key: 'accountCompanyName',
    default: null
})

export const selectedAccountStateAtom = atom<accountInterface|null>({
    key : 'selectedAccountState',
    default : null,
});

export const changeUserAtom = atom<changeUserInterface | null>({
    key: 'changeUserAtom',
    default: null,
});

export const newAccountModalAtom = atom<boolean> ({
    key: 'newAccountModalAtom',
    default: false
});

export const modifyAccountModalAtom = atom<boolean> ({
    key: 'modifyAccountModalAtom',
    default: false
});

export const deleteModalAtom = atom<boolean> ({
    key: 'deleteModalAtom',
    default: false
});

export const textAlertErrorAtom = atom<string> ({
    key: 'textAlertErrorAtom',
    default: ''
});
import { mapStateAtom } from '../models/atoms/ui_atoms';
import { authStateAtom } from '../models/atoms/auth_atoms';
import { deviceListStateAtom, selectedDeviceStateAtom, filterStateAtom, requestIDAtom, dateRangeAtom } from '../models/atoms/device_atoms';
import { getRecoil, setRecoil } from "recoil-nexus";
import { deviceListInterface, deviceInterface, deviceDataInterface } from '../models/interfaces/device_interfaces';


const device_link = process.env.REACT_APP_DEVICE_API_URL;

export const device_controller = {
  get_device_list: async function (callback = () => { }) {
    try {
      const filter = getRecoil(filterStateAtom);

      // Ensure filter and filter.labels are defined, this was breaking the test
      if (!filter || !filter.labels) {
        console.error('Filter or filter.labels is undefined');
        return;
      }

      const params = new URLSearchParams({
        pinned: filter.pinned?.toString() || '',
      });

      filter.labels.forEach(label => {
        params.append('labels', label);
      });

      const obj = {
        link: `${device_link}/devices?${params.toString()}`,
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${getRecoil(authStateAtom).token}`,
          }
        }
      };

      const response = await fetch(obj.link, obj.object);

      if (!response.ok) {
        console.error('Failed to fetch device list:', response.status, response.statusText);
        return;
      }

      const deviceList = await response.json() as deviceListInterface;
      setRecoil(deviceListStateAtom, deviceList);
      callback();
    } catch (error) {
      console.error('Error fetching device list:', error);
    }
  },

  select_device: async function (device_id: string, measurement_period_type: string = "all", measurement_period_start_date: number = -1) {
    if (measurement_period_start_date === -1) {
      measurement_period_start_date = new Date().setHours(0, 0, 0, 0);
    }
    console.log('measurement_period_start_date: ', measurement_period_start_date / 1000)
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: measurement_period_type,
        measurement_period_start_date: (measurement_period_start_date / 1000).toString(),
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    await fetch(obj.link, obj.object)
    .then(async response => {
      const data = await response.json() as deviceInterface;
      // console.log('Response data:',data);
      return data;
    })
    .then(device => {
      // console.log('Device:', device);
      setRecoil(selectedDeviceStateAtom, device);
      return device;
    })
      .then(data => {
        setRecoil(mapStateAtom,
          {
            requested_centre: [data.longitude, data.latitude] as [number, number],
            required_update: true,
            requested_zoom: 12,
          })
      });
    const value = getRecoil(requestIDAtom);
    setRecoil(requestIDAtom, value + 1);
    this.get_device_data(device_id, value + 1, measurement_period_type, measurement_period_start_date);
  },

  get_device_data: async function (device_id: string, request_id: number, measurement_period_type: string = "day", measurement_period_start_date: number = -1) {
    var nextIndex = 0;
    do {
      var obj = {
        link: device_link + '/device/data?' + new URLSearchParams({
          device_id: device_id,
          measurement_period_type: measurement_period_type,
          measurement_period_start_date: (measurement_period_start_date / 1000).toString(),
          index: nextIndex.toString(),
          limit: '1000',
          request_id: request_id.toString(),
        }),
        object: {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
          }
        }
      };
      const device: deviceDataInterface = (await (await fetch(obj.link, obj.object)).json());
      const selectedDevice = getRecoil(selectedDeviceStateAtom);
      const current_request_id = getRecoil(requestIDAtom);
      if (!selectedDevice || device.device_id !== selectedDevice.device_id || current_request_id !== device.request_id) {
        return;
      }
      nextIndex = device.next_index;
      console.log(device);
      setRecoil(selectedDeviceStateAtom, (device_state) => {
        if (!device_state) {
          return null;
        }
        return {...device_state, measurements: [...device_state.measurements, ...device.measurements], rainfall_levels: [...device_state.rainfall_levels, ...device.rainfall_levels]};
      });
      console.log(nextIndex);
    } while (nextIndex);
    console.log(measurement_period_start_date);
    if (measurement_period_type === "all") {
      const selectedDevice = getRecoil(selectedDeviceStateAtom);
      if (!selectedDevice || selectedDevice.measurements.length === 0) {
        return;
      }
      console.log(selectedDevice.measurements[0].measurement_unix_timestamp, selectedDevice.measurements[selectedDevice.measurements.length - 1].measurement_unix_timestamp);
      setRecoil(dateRangeAtom, {start: selectedDevice.measurements[0].measurement_unix_timestamp * 1000, end: selectedDevice.measurements[selectedDevice.measurements.length - 1].measurement_unix_timestamp * 1000});
    }
  },

  hide_device: function (device_id: string, callback = () => {}) {
    var obj = {
      link: device_link + '/device/hide?' + new URLSearchParams({
        device_id: device_id,
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        setRecoil(selectedDeviceStateAtom, null);
        this.get_device_list(callback)
      });
  },

  toggle_device_pin: function (device_id: string, callback = () => { }) {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: 'day',
        measurement_period_start_date: '0',
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        device.pinned = !device.pinned;
        var obj = {
          link: device_link + '/device',
          object: {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
              'Content-type': 'application/json'
            },
            body: JSON.stringify(
              device
            )
          }
        };
        fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
      });
  },

  change_device_comments: function (device_id: string, comments: string, callback = () => { }) {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: 'day',
        measurement_period_start_date: '0',
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };
    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        device.comments = comments;
        var obj = {
          link: device_link + '/device',
          object: {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
              'Content-type': 'application/json'
            },
            body: JSON.stringify(
              device
            )
          }
        };
        fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
      });
  },

  change_device_warning_level_percentage: function (device_id: string, warning_level_percentage: number, callback = () => { }) {
    var obj = {
      link: device_link + '/device?' + new URLSearchParams({
        device_id: device_id,
        measurement_period_type: 'none',
        measurement_period_start_date: '0',
      }),
      object: {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
        }
      }
    };

    fetch(obj.link, obj.object)
      .then(async response => await response.json() as deviceInterface)
      .then(device => {
        device.warning_level_percentage = warning_level_percentage;
        var obj = {
          link: device_link + '/device',
          object: {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
              'Content-type': 'application/json'
            },
            body: JSON.stringify(
              device
            )
          }
        };
        fetch(obj.link, obj.object).then(() => { this.get_device_list(); callback(); this.select_device(device_id); });
      });
  },
}
import React from 'react';
import { memo, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { newAccountModalAtom, deleteModalAtom, modifyAccountModalAtom, selectedAccountStateAtom } from "../../../models/atoms/account_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { css } from 'aphrodite';
import '../../../App.css';
import {
    Button,
    CardText,
    Row,
    Col,
    FormGroup, Label,
    Modal, ModalHeader, ModalBody, Input, Form,
} from "reactstrap";
/* eslint-disable no-useless-escape */
import { accountsPageStyle } from "../../styles/accounts_page_styles";
import { useTranslation } from '../../../contexts/TranslationContext';

export const NewAccountModalComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const [newAccountModal, setNewAccountModal] = useRecoilState(newAccountModalAtom);
    const [nameError, setNameError] = useState('');
    const [passwordValidationError, setPasswordValidationError] = useState('');
    const [matchPasswordsError, setMatchPasswordsEror] = useState(''); 
    const [emailValidationError, setEmailValidationError] = useState("");
    const [phoneValidationError, setPhoneValidationError] = useState("");
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const onFormSubmitAccount: React.FormEventHandler<HTMLFormElement> = (e : React.FormEvent) => {
        e.preventDefault();
        let errors_flag = false
        if (matchPasswordsError) {
            return
        }

        const target = e.target as typeof e.target & {
            newEmail: { value: string },
            newPassword: { value: string },
            repeatNewPassword: { value: string },
            newView: { checked: boolean },
            newInstall: { checked: boolean },
            newAdmin: { checked: boolean },
            newFirstName: { value: string },
            newLastName: { value: string },
            newPhoneNumber: { value: string},
        };

        if (!target.newFirstName.value || !target.newLastName.value || target.newFirstName.value.length < 2 || target.newLastName.value.length < 3) {
            setNameError(t('First and Last names cannot be empty and must be at least 2 characters long!'));
            errors_flag = true
        }
        else if (!(/^[a-zA-Z]+$/.test(target.newFirstName.value)) || !(/^[a-zA-Z]+$/.test(target.newLastName.value))) {
            setNameError(t('Ensure that both the First and Last names exclusively consist of valid English alphabet characters!'));
            errors_flag = true
        } 
        else {
            setNameError('');
        }

        if (!target.newEmail.value || target.newEmail.value.length < 5 ||  !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(target.newEmail.value)) {
          setEmailValidationError(t("Please provide a valid email address!"));
          errors_flag = true
        }
        else {
          setEmailValidationError("");
        }

        if (target.newPassword.value.length < 12 || !(/\d/).test(target.newPassword.value) || !(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/).test(target.newPassword.value)) {
          setPasswordValidationError(t("Password must be at least 12 characters and contain at least 1 number and 1 special character!"));
          errors_flag = true
        } 
         
        if (target.newPhoneNumber.value) {
          if (!(/^[0-9+]+$/).test(target.newPhoneNumber.value) || target.newPhoneNumber.value.length < 8) {
            setPhoneValidationError(t("Please provide a valid phone number!"));
            errors_flag = true
          }
          else {
            setPhoneValidationError("");
          }
        }
        else {
          setPhoneValidationError('');
        }

        if (!errors_flag) {
          account_controller.create_account(
            target.newEmail.value.toLowerCase(),
            target.newPassword.value,
            target.newView.checked,
            target.newInstall.checked,
            target.newAdmin.checked,
            target.newAdmin.checked,
            target.newView.checked,
            target.newInstall.checked,
            target.newFirstName.value,
            target.newLastName.value,
            (target.newPhoneNumber.value ? target.newPhoneNumber.value : '-' ),
        )
        setNewAccountModal(false);
        }
    }

    return <Modal isOpen={true}>
    <ModalHeader toggle={() => {
       setNewAccountModal(!newAccountModal);
       setNameError("");
       setPhoneValidationError("");
       setEmailValidationError("");
       setPasswordValidationError("");
       }}>
        {t('Create New Account')}
    </ModalHeader>
    <ModalBody>
        <Form onSubmit={(e) => onFormSubmitAccount(e)}>
            <legend style={{fontSize: '1.2em'}}>{t('Details')}</legend>
            <FormGroup valid>
                <Label for="newFirstName">{t('First Name')}*</Label>
                <Input name="firstName" id="newFirstName" placeholder="" onChange={() => setNameError("")}></Input>
                <p></p>
                <Label for="newLastName">{t('Last Name')}*</Label>
                <Input name="lastName" id="newLastName" placeholder=""  onChange={() => setNameError("")}></Input>
                <p className="password-alert">
                {nameError}
                </p>
                <Label for="newEmail">{t('Email')}*</Label>
                <Input type="email" name="email" id="newEmail" placeholder=""  onChange={() => setEmailValidationError("")}></Input>
                <p className="password-alert">
                {emailValidationError}
                </p>
                <Label for="newPhoneNumber">{t('Phone Number (+country code without leading zero or spaces)')}</Label>
                <Input name="phoneNumber" id="newPhoneNumber" placeholder="e.g +447XXXXXXXXX"  onChange={() => setPhoneValidationError("")}></Input>
                <p className="password-alert">
                {phoneValidationError}
                </p>
                <Label for="newPassword">
                        {t('Password')}* <a className={css(accountsPageStyle.link)} href="https://device-service.manhole-metrics.com/redirect-to-password-policy">({t('View our Password Policy')})</a>
                </Label>
                <Input type="password" name="password" id="newPassword" value={password}
                    placeholder=""  onChange={(e) => {
                        setPasswordValidationError("");
                        setPassword(e.target.value);
                    }}></Input>
                <p></p>
                <Label for="repeatNewPassword" style={{marginTop: 5}}>{t('Repeat Password')}*</Label>
                <Input type="password" name="confirm_password" id="repeatNewPassword" value={repeatPassword}
                    placeholder=""  onChange={(e) => {
                        setPasswordValidationError("");
                        if (e.target.value !== password) {
                            setMatchPasswordsEror(t("Passwords don't match!"));
                        } else {
                            setMatchPasswordsEror('');
                        }
                        setRepeatPassword(e.target.value);
                    }}></Input>
                <p className="password-alert">
                {passwordValidationError}
                </p>
                <p className="password-alert">
                {matchPasswordsError}
                </p>

            </FormGroup>
            <legend style={{fontSize: '1.2em'}}>{t('Permissions')}</legend>
            <FormGroup check>
                <Label check>
                    <Input id="newView" type="checkbox" />
                    {t('View')}
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input id="newInstall" type="checkbox" />
                    {t('Install')}
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input id="newAdmin" type="checkbox" />
                    {t('Admin')}
                </Label>
            </FormGroup>
            <br></br>
            <Button style={{fontWeight: 500}} type="submit" disabled={(nameError || emailValidationError || phoneValidationError || passwordValidationError) ? true : false}>{t('Save')}</Button>
        </Form>
    </ModalBody>
</Modal>
})

export const ModifyAccountModalComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const selectedAccount = useRecoilValue(selectedAccountStateAtom)
    const [modifyPassword, setModifyPassword] = useState("");
    const [modifyAccountModal, setModifyAccountModal] = useRecoilState(modifyAccountModalAtom);
    const [passwordValidationError, setPasswordValidationError] = useState('');

    const toggle_permission = (permission_key: 'view_devices' | 'register_devices' | 'manage_accounts') => {
        if (selectedAccount != null) {
            var permissions = {...selectedAccount.permissions}
            permissions[permission_key] = !permissions[permission_key]
            setRecoil(selectedAccountStateAtom, {
                ...selectedAccount,
                permissions: permissions
            }
            )
        }
    }

    const onFormSubmitModifyAccount: React.FormEventHandler<HTMLFormElement> = (e : React.FormEvent) => {
        e.preventDefault();
        if (selectedAccount !== null) {
            const target = e.target as

 typeof e.target & {
                modifyView: { checked: boolean },
                modifyInstall: { checked: boolean },
                modifyAdmin: { checked: boolean },
            };
            account_controller.modify_account(
                selectedAccount.id,
                target.modifyView.checked,
                target.modifyInstall.checked,
                target.modifyAdmin.checked,
                target.modifyAdmin.checked,
                target.modifyView.checked,
                target.modifyInstall.checked
            )
        }
        setModifyAccountModal(false);
    }

    if (!selectedAccount) {
        return <></>
    }
    
    return <Modal isOpen={true}>
            <ModalHeader toggle={() => { 
                setModifyAccountModal(!modifyAccountModal);
                setRecoil(selectedAccountStateAtom, null);
                 }} style={{fontSize: '1.6em'}}>
                {t('Modify Account')}
            </ModalHeader>
            <ModalBody>
                <legend className={css(accountsPageStyle.form_title)}>{t('Name')}</legend>
                <CardText>{selectedAccount.first_name} {selectedAccount.last_name}</CardText>
                <legend className={css(accountsPageStyle.form_title)}>{t('Phone Number')}</legend>
                <CardText>{selectedAccount.phone_number === '0' ? 'Na' : selectedAccount.phone_number}</CardText>
                <legend className={css(accountsPageStyle.form_title)}>{t('Email')}</legend>
                <CardText>{selectedAccount.email}</CardText>
                {/* submit */}
                <Form onSubmit={onFormSubmitModifyAccount}>
                    <legend className={css(accountsPageStyle.form_title)}>{t('Change Permissions')}</legend>
                    <FormGroup check>
                        <Label check>
                            <Input id="modifyView" type="checkbox"
                                defaultChecked={selectedAccount.permissions.view_devices}
                                onChange={() => { toggle_permission('view_devices'); }}
                            />
                            {t('View')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input id="modifyInstall" type="checkbox"
                                defaultChecked={selectedAccount.permissions.register_devices}
                                onChange={() => { toggle_permission('register_devices'); }} />
                            {t('Install')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>

                            <Input id="modifyAdmin" type="checkbox"
                                defaultChecked={selectedAccount.permissions.manage_accounts}
                                onChange={() => { toggle_permission('manage_accounts'); }} />
                            {t('Admin')}
                        </Label>
                    </FormGroup>
                    <Button type="submit" className={css(accountsPageStyle.submit_button_style)}>{t('Save')}</Button>
                </Form>
                {/* delete */}
                <Button className={css(accountsPageStyle.delete_button_style)}
                    onClick={() => { setModifyAccountModal(false); setRecoil(deleteModalAtom,true) }}>
                    {t('Delete')}
                </Button>
                <br />
                <br />
                {/** modify password */}
                <legend className={css(accountsPageStyle.form_title)}>{t('Change Password')}</legend>
                <CardText>
                    <Input type="password" placeholder={t("New Password")} onChange={(s) => {
                      setPasswordValidationError('');
                      setModifyPassword(s.target.value);
                      if (modifyPassword.length < 12) {
                        setPasswordValidationError(t("Password must be at least 12 characters"));
                      }
                    }
                      }></Input>
                    <p className="password-alert">
                    {passwordValidationError}
                    </p>
                    <Label for="newPassword">
                        <a className={css(accountsPageStyle.link)} href="https://device-service.manhole-metrics.com/redirect-to-password-policy">{t('View our Password Policy')}</a>
                    </Label>
                </CardText>
                <Button className={css(accountsPageStyle.submit_button_style)}
                    disabled={passwordValidationError ? true : false}
                    onClick={() => {
                        if (!passwordValidationError) {
                          account_controller.change_account_password(selectedAccount.id, modifyPassword);
                          setModifyAccountModal(false)
                        }
                    }}>{t('Save')}</Button>
            </ModalBody>
        </Modal>
})

export const DeleteModalComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const selectedAccount = useRecoilValue(selectedAccountStateAtom);

    return <Modal isOpen={true} className={css(accountsPageStyle.delete_model_style)}>
    <ModalHeader className={css(accountsPageStyle.delete_model_header)}>{t('Confirm Deletion')}</ModalHeader>
    <ModalBody>
        <Row>
            <Col xs="6" className="d-flex">
                <Button className={css(accountsPageStyle.cancel_button_style)}
                    onClick={() => {
                        setRecoil(deleteModalAtom, false)
                    }}
                >
                    {t('Cancel')}
                </Button></Col>
            <Col xs="6" className="d-flex">
                <Button className={css(accountsPageStyle.confirm_delete_button_style)}
                    onClick={() => {
                        if (selectedAccount == null) { return };
                        account_controller.delete_account(selectedAccount.id);
                        setRecoil(modifyAccountModalAtom, false);
                        setRecoil(deleteModalAtom, false);
                    }}>
                    {t('Delete')}
                </Button>
            </Col>
        </Row>
    </ModalBody>
</Modal>
})